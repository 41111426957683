import { SVGProps } from "react";

export function AptosLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="125" height="125" viewBox="0 0 125 125" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M100.03 41.35h-10.6a4.31 4.31 0 0 1-3.22-1.45l-4.3-4.85a3.37 3.37 0 0 0-5.06 0l-3.68 4.16a6.36 6.36 0 0 1-4.77 2.15H10.38A59.79 59.79 0 0 0 7.22 56.2H62c.96 0 1.88-.39 2.55-1.09l5.1-5.32a3.38 3.38 0 0 1 2.43-1.04h.22c.96 0 1.88.42 2.52 1.14l4.3 4.86c.82.92 2 1.45 3.23 1.45h44.7a59.72 59.72 0 0 0-3.15-14.84h-23.87ZM40.37 87.42c.96 0 1.89-.4 2.55-1.09l5.1-5.32a3.38 3.38 0 0 1 2.44-1.04h.2c.98 0 1.9.41 2.54 1.14l4.3 4.85a4.26 4.26 0 0 0 3.22 1.46h60.59a59.66 59.66 0 0 0 4.92-15H68.07a4.31 4.31 0 0 1-3.22-1.45l-4.3-4.86a3.37 3.37 0 0 0-5.05 0l-3.7 4.17a6.36 6.36 0 0 1-4.75 2.14h-39a59.76 59.76 0 0 0 4.92 15h27.4Zm42.99-62.29c.96 0 1.88-.4 2.55-1.09l5.1-5.32a3.38 3.38 0 0 1 2.43-1.04h.21c.97 0 1.89.41 2.53 1.14l4.3 4.86a4.3 4.3 0 0 0 3.22 1.45h11.53A60.04 60.04 0 0 0 67.14 1.11a60.05 60.05 0 0 0-48.09 24.02h64.3Zm-23.15 77.15H44.45a4.31 4.31 0 0 1-3.23-1.45l-4.3-4.86a3.37 3.37 0 0 0-5.05 0l-3.69 4.17a6.36 6.36 0 0 1-4.76 2.14h-.24a59.96 59.96 0 0 0 43.97 19.13 59.97 59.97 0 0 0 43.96-19.13h-50.9Z"
      />
    </svg>
  );
}
